import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import {
  getFeaturedPost,
  getUpcomingPosts,
  removeFeatureAndSubscribePosts,
  filterPostsFromSplashAndToolbox,
} from 'components/Page/Webinar/utils/postsUtils';
import { SectionLayout } from 'components/Sections/SectionLayout';
import ContentfulLayout from 'components/Contentful/ContentfulLayout';
import ToolboxSplashHero from 'components/Page/Webinar/Hero';
import ToolboxSplashUpcoming from 'components/Page/Webinar/Upcoming';
import ToolboxSplashPostsContainer from 'components/Page/Webinar/PostsContainer';
import useIsClient from 'hooks/useIsClient';
import headsetIcon from 'images/headset-black.svg';

const ToolboxSplash = (props) => {
  const seo = props?.data?.seo;
  const header = props.data.componentCollection.components.find(
    (component) => component.__typename === 'ContentfulHeader',
  );
  const hero = props.data.componentCollection.components.find(
    (component) => component.__typename === 'ContentfulLayout',
  );
  const allWebinarPosts = props?.data?.allContentfulWebinar?.edges?.map((webinar) => webinar.node);
  const placeholderImage = props?.data?.placeholderImage?.image?.file;
  const noResultsImage = props?.data?.allContentfulImage?.edges[0]?.node?.image?.file;
  const splashPagePosts = filterPostsFromSplashAndToolbox(allWebinarPosts);
  const splashPostsForDisplay = removeFeatureAndSubscribePosts(splashPagePosts);
  const upcomingPosts = getUpcomingPosts(splashPagePosts);
  const featuredPost = getFeaturedPost(splashPagePosts);
  const isClient = useIsClient();

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Webinars',
        icon: headsetIcon,
        url: '',
        hideInMobile: true,
      },
    ],
    absolute: true,
    theme: 'light',
  };

  return (
    <Container>
      <ToolboxLayout
        breadcrumbs={breadcrumbs}
        contentfulSeo={seo}
        header={header}
        theme="TRANS_BG_WHITE_COLOR"
        layout="industry"
        {...props}
      >
        <ContentfulLayout {...hero} className="webinar-hero" />
        <SectionLayout className="webinar-register" contentBelowSection>
          <ToolboxSplashHero featuredPost={featuredPost} placeholderImage={placeholderImage} />
        </SectionLayout>
        {upcomingPosts.length > 0 && isClient && (
          <SectionLayout contentBelowSection>
            <ToolboxSplashUpcoming upcomingPosts={upcomingPosts} />
          </SectionLayout>
        )}
        <SectionLayout>
          <ToolboxSplashPostsContainer
            posts={splashPostsForDisplay}
            placeholderImage={placeholderImage}
            noResultsImage={noResultsImage}
          />
        </SectionLayout>
      </ToolboxLayout>
    </Container>
  );
};

export const WebinarPageTemplatePageQuery = graphql`
  query WebinarToolboxSplashPageQuery {
    allContentfulWebinar {
      edges {
        node {
          ...Webinar
        }
      }
    }
    componentCollection: contentfulComponentCollection(
      contentful_id: { eq: "3Xz7iXkvUD22tCV22KMtu9" }
    ) {
      ... on ContentfulComponentCollection {
        components {
          ...Header
          ... on ContentfulLayout {
            __typename
            componentMap
            stylesEntries: styles {
              ...Styles
            }
            section: sectionContent {
              ...Section
            }
            richText {
              ...RichText
            }
            template {
              ...Section
            }
          }
        }
      }
    }
    seo: contentfulSeo(contentful_id: { eq: "7s0QshGKMBJqyNbKTJr33I" }) {
      ...Seo
    }
    placeholderImage: contentfulImage(contentful_id: { eq: "8TSIiE3INirzb6LpyqmvF" }) {
      ...WebsiteImage
    }
    contentfulFooter(contentful_id: { eq: "2B5mDNSOuDSO2Pr5QDlbg" }) {
      ...Footer
    }
  }
`;

export default ToolboxSplash;

const Container = styled.div`
  .website-header .website-header--main-menu {
    background: transparent;
  }

  .website-header.scrolled .website-header--main-menu {
    background: var(--white);
  }

  .main-container {
    padding: 0;
  }

  .webinar-hero .section-container-row {
    padding: 250px 28px 75px 28px !important;

    @media (max-width: 1199px) {
      padding: 215px 28px 75px 28px !important;
    }

    @media (max-width: 767px) {
      padding: 200px 28px 50px 28px !important;
    }
  }

  .webinar-register .section-layout--inner-container {
    & > div {
      margin: 0;
      padding: 0;

      @media (max-width: 991px) {
        & > div {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
`;
